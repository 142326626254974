import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import firebase from "firebase";
import Meta from "vue-meta";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

Vue.component("vue-phone-number-input", VuePhoneNumberInput);
Vue.use(Meta);

Vue.config.productionTip = false;
Vue.component("Header", require("./components/Header.vue").default);
Vue.component("Footer", require("./components/Footer.vue").default);
Vue.component("SuccessStory", require("./components/SuccessStory.vue").default);
Vue.component("SixMonths", require("./components/SixMonths.vue").default);
Vue.component(
  "InternshipProcess",
  require("./components/InternshipProcess.vue").default
);
Vue.component("LifeStyle", require("./components/LifeStyle.vue").default);
Vue.component("BeforeCT", require("./components/BeforeCT.vue").default);
Vue.component(
  "InternProfile",
  require("./components/InternProfile.vue").default
);
Vue.component("RightIntern", require("./components/RightIntern.vue").default);
Vue.component("Sidebar", require("./components/Sidebar.vue").default);
Vue.component("ModalIntern", require("./components/ModalIntern.vue").default);
Vue.component(
  "ModalBusiness",
  require("./components/ModalBusiness.vue").default
);
Vue.component("ModalFAQ", require("./components/ModelFAQ.vue").default);
//Cards
Vue.component("BACard", require("./components/BACard.vue").default);
Vue.component("SMSCard", require("./components/SMSCard.vue").default);
Vue.component("WebCard", require("./components/WebCard.vue").default);
Vue.component("FACard", require("./components/FACard.vue").default);
Vue.component("PMCard", require("./components/PMCard.vue").default);
Vue.component("ComCard", require("./components/ComCard.vue").default);
Vue.component("BECard", require("./components/BECard.vue").default);
Vue.component("DMCard", require("./components/DMCard.vue").default);
Vue.component("GDCard", require("./components/GDCard.vue").default);
Vue.component(
  "InternshipProcessCard",
  require("./components/InternshipProcessCard.vue").default
);
Vue.component(
  "InternProfileCard",
  require("./components/InternProfileCard.vue").default
);
Vue.component(
  "LifestyleBudgetCard",
  require("./components/LifestyleBudgetCard.vue").default
);
Vue.component(
  "CapeTownInternshipCard",
  require("./components/CapeTownInternshipCard.vue").default
);
Vue.component(
  "RightInternCard",
  require("./components/RightInternCard.vue").default
);
Vue.component("B4CTCard", require("./components/B4CTCard.vue").default);
Vue.component("JobPositions", require("./components/JobPositions.vue").default);
Vue.component(
  "HowItWorksIntern",
  require("./components/HowItWorksIntern.vue").default
);
Vue.component(
  "HowItWorksBusiness",
  require("./components/HowItWorksBusiness.vue").default
);
Vue.component("SideButton", require("./components/SideButton.vue").default);
Vue.component("FAQCard", require("./components/FAQCard.vue").default);
Vue.component("HomePage", require("./views/Home.vue").default);

var firebaseConfig = {
  apiKey: "AIzaSyDbtOEdMcHaOD4D66EnhSdN9ltbNb2l7a0",
  authDomain: "ict-cv-uploader.firebaseapp.com",
  projectId: "ict-cv-uploader",
  storageBucket: "ict-cv-uploader.appspot.com",
  messagingSenderId: "226626072628",
  appId: "1:226626072628:web:8e7b8db91503bdde1e1a61",
  measurementId: "G-B96W4MJZSQ"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
