<template>
  <main class="bg-dg lg-pt">
    <section class="container mt-5">
      <div class="row pb-5">
        <div class="col-md-6">
          <form class="bg-gb p-5">
            <!--Grid row-->
            <div class="row">
              <!--Grid column-->
              <div class="col-md-12">
                <div class="form-floating">
                  <input
                    v-model="name"
                    type="text"
                    id="name"
                    name="name"
                    class="form-control"
                    placeholder="Name"
                  /><label for="name" class="">Name</label>
                </div>
              </div>
              <!--Grid column-->
            </div>
            <!--Grid row-->
            <!--Grid row-->
            <div class="row">
              <!--Grid column-->
              <div class="col-md-12">
                <div class="form-floating">
                  <select
                    v-model="code"
                    type="text"
                    id="code"
                    name="code"
                    class="form-control"
                  >
                    <option
                      v-for="(countryCode, index) in country"
                      :key="index"
                      v-bind:value="countryCode.callingCodes"
                      >{{ countryCode.name }}</option
                    >
                  </select>
                  <label for="code" class="country-code">Country</label>
                </div>
              </div>
              <!--Grid column-->
              <!--Grid column-->
              <div class="col-4">
                <p class="text-underlined label-style">
                  Calling Code<br /><span class="text-black font-weight-bolder"
                    >(+{{ code[0] }})</span
                  >
                </p>
              </div>
              <div class="col-md-8">
                <div class="form-floating mb-0">
                  <input
                    v-model="number"
                    type="text"
                    id="phone"
                    name="phone"
                    class="form-control"
                    placeholder="Phone"
                  /><label for="phone" class="">Phone</label>
                </div>
              </div>
              <!--Grid column-->
            </div>
            <!--Grid row-->

            <!--Grid row-->
            <div class="row">
              <div class="col-md-12">
                <div class="form-floating mb-0">
                  <input
                    v-model="email"
                    type="text"
                    id="email"
                    name="email"
                    class="form-control"
                    placeholder="email"
                  /><label for="email" class="">Email</label>
                </div>
              </div>
            </div>
            <!--Grid row-->
            <!--Grid row-->
            <div class="row">
              <div class="col-md-12">
                <div class="form-floating mb-0">
                  <input
                    v-model="type"
                    type="text"
                    id="type"
                    name="type"
                    class="form-control"
                    placeholder="type"
                  /><label for="type" class=""
                    >Which field are you looking for an internship in?</label
                  >
                </div>
              </div>
            </div>
            <!--Grid row-->
            <!--Grid row-->
            <div class="row">
              <div class="col-md-12">
                <div class="form-floating mb-0">
                  <input
                    v-model="time"
                    type="text"
                    id="time"
                    name="time"
                    class="form-control"
                    placeholder="time"
                  /><label for="time" class="">For what period of time?</label>
                </div>
              </div>
            </div>
            <!--Grid row-->

            <!--Grid row-->
            <div>
              <vue-dropzone
                ref="fileDropzone"
                id="myVueDropzone"
                :options="dropzoneOptions"
                @vdropzone-complete="afterComplete"
              ></vue-dropzone>
            </div>
            <div class="pt-5 pb-5">
              <p v-if="finishedUploading">
                CV and Cover Letter Uploaded
                <i class="fas fa-check-circle"></i>
              </p>
              <p v-else>{{ fileNo }}</p>
            </div>

            <div class="text-center text-md-left  submit-button">
              <button
                v-if="finishedUploading"
                type="button"
                class="btn-orange text-white estimate-submit btn btn-block"
                @click="getInContact()"
              >
                <div
                  v-if="loading"
                  class="spinner-border spinner-border-md"
                ></div>
                <p class="text-white pt-3">
                  <span v-if="loading"> Sending</span>
                  <span v-else>{{ title }}</span>
                </p>

                <!-- <h5 className="pt-3 text-uppercase">{{ title }}</h5> -->
              </button>
              <button
                v-else
                type="button"
                class="btn-orange text-white estimate-submit btn btn-block"
                @click="getInContact()"
                disabled
              >
                <div
                  v-if="loading"
                  class="spinner-border spinner-border-md"
                ></div>
                <p class="text-white pt-3">
                  <span v-if="loading"> Sending</span>
                  <span v-else>{{ title }}</span>
                </p>
              </button>
            </div>
            <!--Grid row-->
          </form>
        </div>
        <!-- FORM END -->
        <!-- CARDS -->
        <div class="col-md-6">
          <section class="container sm-pb">
            <h2 class="text-white text-capitalize text-center">
              find the internship of your dreams!
            </h2>
            <div id="home-5" class="sm-pt">
              <div class="job-positions">
                <div class="position-grid positions text-center sm-pt">
                  <div class="form-check">
                    <input
                      v-model="Card"
                      class="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="experience"
                      value="Experience"
                    />
                    <label class="form-check-label icon-set" for="experience">
                      <svg class="icon-svg">
                        <use
                          xlink:href="../assets/experience.svg#Layer_1"
                        ></use></svg
                      ><br />
                      Experience
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="Card"
                      class="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="matching"
                      value="Matching"
                    />
                    <label class="form-check-label icon-set" for="matching">
                      <svg class="icon-svg">
                        <use
                          xlink:href="../assets/matches.svg#Layer_1"
                        ></use></svg
                      ><br />
                      Matching
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="Card"
                      class="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="assistance"
                      value="Assistance"
                    />
                    <label class="form-check-label icon-set" for="assistance">
                      <svg class="icon-svg">
                        <use
                          xlink:href="../assets/assistance.svg#Layer_1"
                        ></use></svg
                      ><br />
                      Assistance
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="Card"
                      class="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="duration"
                      value="Duration"
                    />
                    <label class="form-check-label icon-set" for="duration">
                      <svg class="icon-svg">
                        <use
                          xlink:href="../assets/duration.svg#Layer_1"
                        ></use></svg
                      ><br />
                      Duration
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="Card"
                      class="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="benefits"
                      value="Benefits"
                    />
                    <label class="form-check-label icon-set" for="benefits">
                      <svg class="icon-svg">
                        <use
                          xlink:href="../assets/benefit.svg#Layer_1"
                        ></use></svg
                      ><br />
                      Benefits
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="description pt-5">
              <div v-if="Card === 'Experience'">
                <p class="text-center">
                  You love being able to choose from a variety of challenging,
                  rewarding roles. You’re happy to step out of your designated
                  job description, ‘get your hands dirty’ and help solve
                  interesting issues facing start-up and scale-up businesses
                  offering internships in Cape Town.
                </p>
              </div>
              <div v-if="Card === 'Matching'">
                <p class="text-center">
                  Start an easy, personalised matching process that enables you
                  to apply to different companies and multiple positions at the
                  same time. Cape Town companies need your international
                  perspective as much as you need to add hands-on, international
                  work experience to your list of professional achievements.
                </p>
              </div>
              <div v-if="Card === 'Assistance'">
                <h4 class="text-center text-white pb-5">
                  What happens after you match with a company?
                </h4>
                <p class="text-center">
                  We reserve the matched position for you for 3 working days.
                  During this time, you will sign the Internship Agreement with
                  the matched company. Should this not be signed within the
                  required period, the position you applied for will
                  automatically open to other candidates.
                </p>
                <p class="text-center">
                  Once you sign your Internship Agreement, your place at the
                  company is secured until your arrival. In the meantime, we
                  assist you with your visa application, accommodation search
                  and any specific fears or concerns you might have.
                </p>
              </div>
              <div v-if="Card === 'Duration'">
                <p class="text-center">3-month placement</p>
                <p class="text-center">3-6-month placement</p>
                <p class="text-center">6 months plus placement</p>
              </div>
              <div v-if="Card === 'Benefits'">
                <p class="text-center">Personalised, guided matching process</p>
                <p class="text-center">
                  Apply to multiple internships simultaneously
                </p>
                <p class="text-center">Pay only when you match</p>
                <p class="text-center">
                  Continued guidance and assistance after your successful match
                </p>
                <p class="text-center">
                  Contribution towards your stay in Cape Town
                </p>
                <p class="text-center">In-person Support while in CT</p>
                <p class="text-center">Airport Pick-up (on request)</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import qs from "qs";
import firebase from "firebase";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

import Country from "../../public/Country.json";
let uuid = require("uuid");
export default {
  name: "intern",
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      files: [],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        addRemoveLinks: false,
        acceptedFiles: ".doc, .docx, .txt, .pdf",
        maxFiles: 1,
        dictDefaultMessage:
          "<i class='fa fa-cloud-upload'></i> UPLOAD CV WITH COVER LETTER",
      },
      loading: false,
      title: "Apply",
      error: "",
      name: "",
      number: "",
      email: "",
      file: "",
      type: "",
      time: "",
      downloadUrl: "",
      fileNo: "Please CV and Cover Letter",
      finishedUploading: false,
      Card: "Experience",
      country: Country,
      code: "1",
      sendError: "",
    };
  },
  methods: {
    async afterComplete(file) {
      try {
        // eslint-disable-next-line no-unused-vars
        const fileName = uuid.v1();
        // const metaData = {
        //   contentType: "application/msword",
        // };
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`files/${fileName}.doc`);
        await fileRef.put(file);
        // eslint-disable-next-line no-unused-vars
        this.downloadUrl = await fileRef.getDownloadURL();
        this.$refs.fileDropzone.removeFile(file);
        this.finishedUploading = true;
      } catch (error) {
        this.finishedUploading = false;
        console.log(error);
      }
    },

    getInContact() {
      if (this.name === "" || this.email === "") {
        this.error = "Please fill out all fields!";
        setTimeout(() => (this.error = ""), 5000);
      } else {
        axios
          .post(
            "/phpmailer/src/intern.php",

            qs.stringify({
              name: this.name,
              email: this.email,
              number: "(+" + this.code[0] + ") " + this.number,
              type: this.type,
              time: this.time,
              file: this.downloadUrl,
            }),
            {
              header: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            this.loading = !false;
            this.title = "Sent :)";
            setTimeout(() => (this.title = "Send"), 5000);
            setTimeout(() => {
              this.loading = !true;
            }, 2000);
            this.name = "";
            this.email = "";
            this.number = "";
            this.code = ["1"];
            this.type = "";
            this.time = "";
            console.log(response);
          })
          .catch(function(error) {
            console.log(error);
            this.loading = !false;
            this.title = "Something Went Wrong :(";
          });
      }
    },
  },
};
</script>
