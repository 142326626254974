import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Home1 from "../views/Home1.vue";
import Articles from "../views/Articles.vue";
import Intern from "../views/Intern.vue";
import NewIntern from "../views/NewIntern.vue";
import Business from "../views/Business.vue";
import Position from "../views/Positions.vue";
import Terms from "../views/Terms.vue";
import Policy from "../views/Policy.vue";
//Articles
import FAQs from "../views/Articles/FAQs.vue";
import BeforeCT from "../views/Articles/BeforeCT.vue";
import InternshipProcess from "../views/Articles/InternshipProcess.vue";
import Lifestyle from "../views/Articles/Lifestyle.vue";
import SixMonths from "../views/Articles/SixMonths.vue";
import SuccessStory from "../views/Articles/SuccessStory.vue";
import InternProfile from "../views/Articles/InternProfile.vue";
import RightIntern from "../views/Articles/RightIntern.vue";
import BusinessFAQ from "../views/Articles/BusinessFAQ.vue";
import InternFAQ from "../views/Articles/InternFAQ.vue";
import SuccessStories from "../views/Articles/SuccessStories.vue";
//Jobs
import DigitalMarketing from "../views/Jobs/DigitalMarketing.vue";
import BusinessAnalyst from "../views/Jobs/BusinessAnalyst.vue";
import SalesMarketingSpecialist from "../views/Jobs/SalesMarketingSpecialist.vue";
import CommunicationsManager from "../views/Jobs/CommunicationsManager.vue";
import WebDeveloper from "../views/Jobs/WebDeveloper.vue";
import GraphicDesigner from "../views/Jobs/GraphicDesigner.vue";
import ProjectManager from "../views/Jobs/PM.vue";
import FinanceAssistant from "../views/Jobs/FinanceAssistant.vue";
import BusinessEngineer from "../views/Jobs/BusinessEngineer.vue";
import SocialMedia from "../views/Jobs/SocialMedia.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/home",
    name: "Home1",
    component: Home1
  },
  {
    path: "/articles",
    name: "Articles",
    component: Articles
  },
  {
    path: "/intern",
    name: "Intern",
    component: Intern
  },
  {
    path: "/new-intern",
    name: "NewIntern",
    component: NewIntern
  },
  {
    path: "/business",
    name: "Business",
    component: Business
  },
  // Articles
  {
    path: "/faqs",
    name: "FAQS",
    component: FAQs
  },
  {
    path: "/beforect",
    name: "BeforeCT",
    component: BeforeCT
  },
  {
    path: "/internship-process",
    name: "InternshipProcess",
    component: InternshipProcess
  },
  {
    path: "/lifestyle",
    name: "Lifestyle",
    component: Lifestyle
  },
  {
    path: "/sixmonths",
    name: "SixMonths",
    component: SixMonths
  },
  {
    path: "/success-story",
    name: "SuccessStory",
    component: SuccessStory
  },
  {
    path: "/intern-profile",
    name: "InternProfile",
    component: InternProfile
  },
  {
    path: "/right-intern",
    name: "RightIntern",
    component: RightIntern
  },
  {
    path: "/positions",
    name: "Position",
    component: Position
  },
  {
    path: "/business-faq",
    name: "BusinessFAQ",
    component: BusinessFAQ
  },
  {
    path: "/intern-faq",
    name: "InternFAQ",
    component: InternFAQ
  },
  {
    path: "/success-stories",
    name: "SuccessStories",
    component: SuccessStories
  },
  //Jobs
  {
    path: "/digital-marketing",
    name: "DigitalMarketing",
    component: DigitalMarketing
  },
  {
    path: "/business-analyst",
    name: "BusinessAnalyst",
    component: BusinessAnalyst
  },
  {
    path: "/sales-communications",
    name: "SalesMarketingSpecialist",
    component: SalesMarketingSpecialist
  },
  {
    path: "/communications-manager",
    name: "CommunicationsManager",
    component: CommunicationsManager
  },
  {
    path: "/web-developer",
    name: "WebDeveloper",
    component: WebDeveloper
  },
  {
    path: "/graphic-designer",
    name: "GraphicDesigner",
    component: GraphicDesigner
  },
  {
    path: "/project-manager",
    name: "ProjectManager",
    component: ProjectManager
  },
  {
    path: "/finance-assistant",
    name: "FinanceAssistant",
    component: FinanceAssistant
  },
  {
    path: "/business-engineer",
    name: "BusinessEngineer",
    component: BusinessEngineer
  },
  {
    path: "/social-media-engagement-manager",
    name: "SocialMedia",
    component: SocialMedia
  },
  {
    path: "/terms-of-service",
    name: "Terms",
    component: Terms
  },
  {
    path: "/privacy-and-cookies-policy",
    name: "Policy",
    component: Policy
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
