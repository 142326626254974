<template>
  <main class="bg-dg">
    <section class="intern-hero hero">
      <div class="container text-center text-md-left p-3">
        <div
          class="row d-flex flex-column justify-content-center justify-content-md-start w-100"
        >
          <h1 class="text-white dropshadow-text">
            Find Internships <span class="text-orange">in Cape Town</span>
          </h1>
          <h3 class="text-white dropshadow-text font-weight-light pr-3">
            Apply to multiple businesses at the same time
          </h3>
          <!-- Button trigger modal -->
          <button
            type="button"
            class="btn btn-yellow text-white hero-button align-self-center align-self-md-start"
            data-toggle="modal"
            data-target="#internModal"
          >
            <h4 class="text-white dropshadow-text">Apply Now</h4>
          </button>
        </div>
      </div>
    </section>

    <section class="container sm-pb">
      <div id="home-5" class="sm-pt">
        <div class="job-positions">
          <div class="position-grid positions text-center sm-pt">
            <div class="form-check">
              <input
                v-model="Card"
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="experience"
                value="Experience"
              />
              <label class="form-check-label icon-set" for="experience">
                <svg class="icon-svg">
                  <use xlink:href="../assets/experience.svg#Layer_1"></use></svg
                ><br />
                Experience
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="Card"
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="matching"
                value="Matching"
              />
              <label class="form-check-label icon-set" for="matching">
                <svg class="icon-svg">
                  <use xlink:href="../assets/matches.svg#Layer_1"></use></svg
                ><br />
                Matching
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="Card"
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="assistance"
                value="Assistance"
              />
              <label class="form-check-label icon-set" for="assistance">
                <svg class="icon-svg">
                  <use xlink:href="../assets/assistance.svg#Layer_1"></use></svg
                ><br />
                Assistance
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="Card"
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="duration"
                value="Duration"
              />
              <label class="form-check-label icon-set" for="duration">
                <svg class="icon-svg">
                  <use xlink:href="../assets/duration.svg#Layer_1"></use></svg
                ><br />
                Duration
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="Card"
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="benefits"
                value="Benefits"
              />
              <label class="form-check-label icon-set" for="benefits">
                <svg class="icon-svg">
                  <use xlink:href="../assets/benefit.svg#Layer_1"></use></svg
                ><br />
                Benefits
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="description pt-5">
        <div v-if="Card === 'Experience'">
          <p class="text-center">
            You love being able to choose from a variety of challenging,
            rewarding roles. You’re happy to step out of your designated job
            description, ‘get your hands dirty’ and help solve interesting
            issues facing start-up and scale-up businesses offering internships
            in Cape Town.
          </p>
        </div>
        <div v-if="Card === 'Matching'">
          <p class="text-center">
            Start an easy, personalised matching process that enables you to
            apply to different companies and multiple positions at the same
            time. Cape Town companies need your international perspective as
            much as you need to add hands-on, international work experience to
            your list of professional achievements.
          </p>
        </div>
        <div v-if="Card === 'Assistance'">
          <h4 class="text-center text-white pb-5">
            What happens after you match with a company?
          </h4>
          <p class="text-center">
            We reserve the matched position for you for 3 working days. During
            this time, you will sign the Internship Agreement with the matched
            company. Should this not be signed within the required period, the
            position you applied for will automatically open to other
            candidates.
          </p>
          <p class="text-center">
            Once you sign your Internship Agreement, your place at the company
            is secured until your arrival. In the meantime, we assist you with
            your visa application, accommodation search and any specific fears
            or concerns you might have.
          </p>
        </div>
        <div v-if="Card === 'Duration'">
          <p class="text-center">3-month placement</p>
          <p class="text-center">3-6-month placement</p>
          <p class="text-center">6 months plus placement</p>
        </div>
        <div v-if="Card === 'Benefits'">
          <p class="text-center">Personalised, guided matching process</p>
          <p class="text-center">
            Apply to multiple internships simultaneously
          </p>
          <p class="text-center">Pay only when you match</p>
          <p class="text-center">
            Continued guidance and assistance after your successful match
          </p>
          <p class="text-center">Contribution towards your stay in Cape Town</p>
          <p class="text-center">In-person Support while in CT</p>
          <p class="text-center">Airport Pick-up (on request)</p>
        </div>
      </div>
      <div class="text-center pb-5">
        <button
          type="button"
          class="btn btn-yellow text-white hero-button"
          data-toggle="modal"
          data-target="#internModal"
        >
          <h4 class="text-white dropshadow-text">Apply Now</h4>
        </button>
      </div>
    </section>

    <section class="bg-white">
      <div class="container">
        <div class="row">
          <div class="col-md-4 white-icons bg-gb sm-pt p-5 text-center">
            <svg class="icon-svg">
              <use xlink:href="../assets/crowd.svg#Layer_1"></use>
            </svg>
            <h4 class="text-white">
              Apply to multiple internships in Cape Town simultaneously
            </h4>
            <p class="text-center">
              We send your profile to various companies in your field of study
              offering internships in Cape Town.
            </p>
          </div>
          <div class="col-md-4 white-icons bg-orange sm-pt p-5 text-center">
            <svg class="icon-svg">
              <use xlink:href="../assets/deal.svg#Layer_1"></use>
            </svg>
            <h4 class="text-white">
              Pay only when you match
            </h4>
            <p class="text-center">
              Application is free and a placement fee applies when you accept
              the offer of a position (your placement fee will depend on the
              length of your internship)
            </p>
          </div>
          <div class="col-md-4 white-icons bg-gb sm-pt p-5 text-center">
            <svg class="icon-svg">
              <use xlink:href="../assets/housing.svg#Layer_1"></use>
            </svg>
            <h4 class="text-white">
              Contribution towards your stay
            </h4>
            <p class="text-center">
              Although internships are unpaid, most of our companies offer a
              contribution to your stay in Cape Town (tax-free stipend,
              accommodation or vouchers). You can discuss this aspect of your
              internship with your company in your interview.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="home-3 sm-pt sm-pb bg-white">
      <div class="container">
        <h2 class="sm-pb text-center">
          How it works
        </h2>
        <div class="grid-container1 text-center">
          <HowItWorksIntern></HowItWorksIntern>
        </div>
      </div>
    </section>

    <div class="">
      <section class="container lg-pt lg-pb">
        <h3 class="text-white text-center">MOST POPULAR POSITIONS</h3>
        <JobPositions></JobPositions>
      </section>
    </div>

    <ModalIntern></ModalIntern>
  </main>
</template>

<script>
export default {
  data() {
    return {
      Card: "Experience",
    };
  },
  metaInfo() {
    return {
      title:
        "Intern Cape Town - Find Internships in Cape Town - Apply to multiple businesses at the same time",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "The Internship Experience of your Dreams. Apply to multiple internships in Cape Town simultaneously",
        },
      ],
    };
  },
};
</script>
