<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="internModal"
      tabindex="-1"
      aria-labelledby="internModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-gb intern p-4">
          <div class="modal-header">
            <h3 class="modal-title text-white pt-5" id="internModalLabel">
              Intern Application
            </h3>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times "></i>
            </button>
          </div>
          <div class="modal-body pt-5">
            <form class=" pb-5">
              <!--Grid row-->
              <div class="row">
                <!--Grid column-->
                <div class="col-md-12">
                  <div class="form-floating">
                    <input
                      v-model="name"
                      type="text"
                      id="name"
                      name="name"
                      class="form-control"
                      placeholder="Name"
                    /><label for="name" class="">Name</label>
                  </div>
                </div>
                <!--Grid column-->
              </div>
              <!--Grid row-->
              <!--Grid row-->
              <div class="row">
                <!--Grid column-->
                <div class="col-md-12">
                  <div class="form-floating">
                    <select
                      v-model="code"
                      type="text"
                      id="code"
                      name="code"
                      class="form-control"
                    >
                      <option
                        v-for="(countryCode, index) in country"
                        :key="index"
                        v-bind:value="countryCode.callingCodes"
                        >{{ countryCode.name }}</option
                      >
                    </select>
                    <label for="code" class="country-code">Country</label>
                  </div>
                </div>
                <!--Grid column-->
                <!--Grid column-->
                <div class="col-4">
                  <p class="text-underlined label-style">
                    Calling Code<br /><span
                      class="text-black font-weight-bolder"
                      >(+{{ code[0] }})</span
                    >
                  </p>
                </div>
                <div class="col-md-8">
                  <div class="form-floating mb-0">
                    <input
                      v-model="number"
                      type="text"
                      id="phone"
                      name="phone"
                      class="form-control"
                      placeholder="Phone"
                    /><label for="phone" class="">Phone</label>
                  </div>
                </div>
                <!--Grid column-->
              </div>
              <!--Grid row-->

              <!--Grid row-->
              <div class="row">
                <div class="col-md-12">
                  <div class="form-floating mb-0">
                    <input
                      v-model="email"
                      type="text"
                      id="email"
                      name="email"
                      class="form-control"
                      placeholder="email"
                    /><label for="email" class="">Email</label>
                  </div>
                </div>
              </div>
              <!--Grid row-->
              <!--Grid row-->
              <div class="row">
                <div class="col-md-12">
                  <div class="form-floating mb-0">
                    <input
                      v-model="type"
                      type="text"
                      id="type"
                      name="type"
                      class="form-control"
                      placeholder="type"
                    /><label for="type" class=""
                      >Which field are you looking for an internship in?</label
                    >
                  </div>
                </div>
              </div>
              <!--Grid row-->
              <!--Grid row-->
              <div class="row">
                <div class="col-md-12">
                  <div class="form-floating mb-0">
                    <input
                      v-model="time"
                      type="text"
                      id="time"
                      name="time"
                      class="form-control"
                      placeholder="time"
                    /><label for="time" class=""
                      >For what period of time?</label
                    >
                  </div>
                </div>
              </div>
              <!--Grid row-->

              <!--Grid row-->
              <div>
                <vue-dropzone
                  ref="fileDropzone"
                  :options="dropzoneOptions"
                  id="myVueDropzone"
                  @vdropzone-complete="afterComplete"
                ></vue-dropzone>
              </div>
              <div class="pt-5 pb-5">
                <p v-if="finishedUploading">
                  CV and Cover Letter Uploaded
                  <i class="fas fa-check-circle"></i>
                </p>
                <p v-else>{{ fileNo }}</p>
              </div>

              <div class="text-center text-md-left  submit-button">
                <button
                  v-if="finishedUploading"
                  type="button"
                  class="btn-orange text-white estimate-submit btn btn-block"
                  @click="getInContact()"
                >
                  <div
                    v-if="loading"
                    class="spinner-border spinner-border-md"
                  ></div>
                  <p class="text-white pt-3">
                    <span v-if="loading"> Sending</span>
                    <span v-else>{{ title }}</span>
                  </p>

                  <!-- <h5 className="pt-3 text-uppercase">{{ title }}</h5> -->
                </button>
                <button
                  v-else
                  type="button"
                  class="btn-orange text-white estimate-submit btn btn-block"
                  @click="getInContact()"
                  disabled
                >
                  <div
                    v-if="loading"
                    class="spinner-border spinner-border-md"
                  ></div>
                  <p class="text-white pt-3">
                    <span v-if="loading"> Sending</span>
                    <span v-else>{{ title }}</span>
                  </p>
                </button>
              </div>
              <!--Grid row-->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import qs from "qs";
import firebase from "firebase";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Country from "../../public/Country.json";
let uuid = require("uuid");
export default {
  name: "intern",
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      files: [],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        addRemoveLinks: false,
        acceptedFiles: ".doc, .docx, .txt, .pdf",
        maxFiles: 1,
        dictDefaultMessage:
          "<i class='fa fa-cloud-upload'></i> UPLOAD CV WITH COVER LETTER",
      },
      loading: false,
      title: "Apply",
      error: "",
      name: "",
      number: "",
      email: "",
      file: "",
      type: "",
      time: "",
      downloadUrl: "",
      fileNo: "Please CV and Cover Letter",
      finishedUploading: false,
      country: Country,
      code: "1",
      sendError: "",
    };
  },
  methods: {
    async afterComplete(file) {
      let fileExtension = file.name.split(".").pop();
      console.log(fileExtension);
      try {
        const fileName = uuid.v1();
        const storageRef = firebase.storage().ref();
        // let filenames = storageRef.split(".").pop();
        let fileExtension = fileName.split(".").pop();
        console.log(fileExtension);
        console.log(fileName);
        const fileRef = storageRef.child(`files/${fileName}.${fileExtension}`);
        console.log(fileRef);
        await fileRef.put(file);
        // eslint-disable-next-line no-unused-vars
        this.downloadUrl = await fileRef.getDownloadURL();
        console.log(this.downloadUrl);
        this.$refs.fileDropzone.removeFile(file);
        this.finishedUploading = true;
      } catch (error) {
        this.finishedUploading = false;
        console.log(error);
      }
    },

    getInContact() {
      if (this.name === "" || this.email === "" || this.downloadUrl === "") {
        this.error = "Please fill out all fields!";
        setTimeout(() => (this.error = ""), 5000);
      } else {
        axios
          .post(
            "/phpmailer/src/intern.php",

            qs.stringify({
              name: this.name,
              email: this.email,
              number: "(+" + this.code[0] + ") " + this.number,
              type: this.type,
              time: this.time,
              file: this.downloadUrl,
            }),
            {
              header: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            this.loading = !false;
            this.title = "Sent :)";
            setTimeout(() => (this.title = "Send"), 5000);
            setTimeout(() => {
              this.loading = !true;
            }, 2000);
            this.name = "";
            this.email = "";
            this.number = "";
            this.code = "";
            this.type = "";
            this.time = "";
            console.log(response);
          })
          .catch(function(error) {
            console.log(error);
            this.sendError = error;
            this.loading = !false;
            this.title = "Something Went Wrong :(";
          });
      }
    },
    phone() {
      console.log(this.code[0] + " " + this.number);
    },
  },
};
</script>
