<template>
  <div class="nav-wrpper">
    <!-- Desktop -->
    <div class="desktop">
      <nav id="navbar-main" class="navbar navbar-expand-lg fixed-top">
        <div class="container-lg container-fluid">
          <router-link class="navbar-brand text-center" to="/home"
            ><div>
              <img
                src="../assets/ict-white-notext.svg"
                class="img-fluid logo-white mx-auto"
                alt=""
              />
              <img
                src="../assets/ict-dark-notext.svg"
                class="img-fluid logo-dark mx-auto"
                alt=""
              />
              <p class="logo-text">INTERN CAPE TOWN</p>
            </div></router-link
          >
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class=""><i class="fas fa-bars fa-2x"></i></span>
          </button>
          <div
            class="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <ul class="navbar-nav font-weight-normal">
              <li class="nav-item  font-weight-normal">
                <router-link
                  class="nav-link active"
                  aria-current="page"
                  to="/intern"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                  >FOR INTERNS</router-link
                >
              </li>
              <li class="nav-item font-weight-normal">
                <router-link
                  class="nav-link active"
                  aria-current="page"
                  to="/business"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                  >FOR BUSINESS</router-link
                >
              </li>
              <li
                class="nav-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <button
                  type="button"
                  class="btn nav-link"
                  style="font-size:1.8rem"
                  data-toggle="modal"
                  data-target="#FAQModal"
                >
                  FAQ
                </button>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle font-weight-normal text-uppercase"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  Programme Information
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-right p-3 text-uppercase"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <router-link
                      class="dropdown-item"
                      to="/beforect"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                      >Before Coming To Cape Town</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item"
                      to="/internship-process"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                      >The Internship Process</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item"
                      to="/lifestyle"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                      >Cape Town Lifestyle</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item"
                      to="/sixmonths"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                      >Six Months In Cape Town</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item"
                      to="/success-story"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                      >Success Story</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item"
                      to="/intern-profile"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                      >Intern Profile</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item"
                      to="/right-intern"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                      >The Right Intern</router-link
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <!-- Desktop End -->
  </div>
</template>

<script>
import $ from "jquery";
$(document).on("scroll", function() {
  if ($(document).scrollTop() > 30) {
    $("#navbar-main").addClass("shrink");
  } else {
    $("#navbar-main").removeClass("shrink");
  }
});

export default {
  name: "HelloWorld",
};
</script>
