<template>
  <div class="articles bg-dg">
    <div class="grid-articles container">
      <div class="article-main" lang="en">
        <SuccessStory></SuccessStory>
      </div>
      <main class="article-side  xl-pt lg-gb">
        <section class="info">
          <h3 class="text-white pb-5">More Information</h3>
          <p class="light">Business FAQ</p>
          <hr class="text-white" />
          <p class="light">Intern FAQ</p>
          <hr class="text-white" />
          <p class="light">Success Story</p>
          <hr class="text-white" />
        </section>
        <section class="posts">
          <h3
            class="display-4 font-weight-bold my-5 text-capitalize text-white"
          >
            Recent Articles
          </h3>
          <ul class="list-unstyled light-text">
            <li class="pb-4">
              <i class="fas fa-file-alt"></i> Safaris and getaways during your
              Cape Town stay
            </li>
            <li class="pb-4">
              <i class="fas fa-file-alt"></i> What to do in Cape Town in 6
              months
            </li>
            <li class="pb-4">
              <i class="fas fa-file-alt"></i> Internship process
            </li>
            <li class="pb-4">
              <i class="fas fa-file-alt"></i> Intern Profiles
            </li>
            <li class="pb-4">
              <i class="fas fa-file-alt"></i> Cape Town Lifestyle & Budget
            </li>
            <li class="pb-4">
              <i class="fas fa-file-alt"></i> Before coming to Cape Town
            </li>
          </ul>
        </section>
        <section>
          <div class="jobs">
            <div class="sidebar-image">
              <img src="../assets/card-image2.jpg" class="img-fluid" alt="" />
            </div>
            <div class="sidebar-image">
              <img src="../assets/card-image3.jpg" class="img-fluid" alt="" />
            </div>
            <div class="sidebar-image">
              <img src="../assets/card-image4.jpg" class="img-fluid" alt="" />
            </div>
            <div class="sidebar-image">
              <img src="../assets/card-image1.jpg" class="img-fluid" alt="" />
            </div>
            <div class="sidebar-image">
              <img src="../assets/card-image5.jpg" class="img-fluid" alt="" />
            </div>
            <div class="sidebar-image">
              <img src="../assets/card-image7.jpg" class="img-fluid" alt="" />
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: "Intern Cape Town - Programme Information",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Find out more about Intern Cape Town’s intern profiles and FAQs to understand what makes our placements – directly from our top international and local  college partners – excellent interns.",
        },
      ],
    };
  },
};
</script>
