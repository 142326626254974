<template>
  <main class="bg-dg">
    <section class="business-hero hero">
      <div class="container text-center text-md-start p-3">
        <div
          class="row d-flex justify-content-center justify-content-md-start w-100"
        >
          <h1 class="text-white pb-4 dropshadow-text">
            Find an Intern <span class="text-yellow">for Your Business</span>
          </h1>
          <h3 class="text-white pb-4 dropshadow-text font-weight-light">
            We assist in helping you find the best candidates to interview
          </h3>
          <!-- Button trigger modal -->
          <button
            type="button"
            class="btn btn-orange text-white hero-button align-self-center align-self-md-start"
            data-toggle="modal"
            data-target="#businessModal"
          >
            <h4 class="text-white dropshadow-text">Sign Up</h4>
          </button>
        </div>
      </div>
    </section>

    <section class="container sm-pb">
      <div id="home-5" class="sm-pt">
        <div class="job-positions">
          <div class="position-grid  w-100 positions text-center sm-pt">
            <div class="form-check">
              <input
                v-model="Card"
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="experience"
                value="Qualified"
              />
              <label class="form-check-label icon-set" for="experience">
                <svg class="icon-svg">
                  <use xlink:href="../assets/experience.svg#Layer_1"></use></svg
                ><br />
                Highly Qualified Interns
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="Card"
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="matching"
                value="Matching"
              />
              <label class="form-check-label icon-set" for="matching">
                <svg class="icon-svg">
                  <use xlink:href="../assets/matches.svg#Layer_1"></use></svg
                ><br />
                Matching
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="Card"
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="assistance"
                value="Assistance"
              />
              <label class="form-check-label icon-set" for="assistance">
                <svg class="icon-svg">
                  <use xlink:href="../assets/assistance.svg#Layer_1"></use></svg
                ><br />
                Assistance
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="Card"
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="free"
                value="Free"
              />
              <label class="form-check-label icon-set" for="free">
                <svg class="icon-svg">
                  <use xlink:href="../assets/cost.svg#Layer_1"></use></svg
                ><br />
                Budget Friendly Service
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="Card"
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="duration"
                value="Duration"
              />
              <label class="form-check-label icon-set" for="duration">
                <svg class="icon-svg">
                  <use xlink:href="../assets/duration.svg#Layer_1"></use></svg
                ><br />
                Duration
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="Card"
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="benefits"
                value="Benefits"
              />
              <label class="form-check-label icon-set" for="benefits">
                <svg class="icon-svg">
                  <use xlink:href="../assets/benefit.svg#Layer_1"></use></svg
                ><br />
                Benefits
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="description pt-5">
        <div v-if="Card === 'Qualified'">
          <p class="text-center">
            Do you have a start-up, scale-up or established company and often
            have projects requiring dedicated, highly-qualified team members for
            shorter periods of time? Instead of permanent staff, you need skills
            suiting an increasingly global marketplace? Find the perfect
            candidate from our top international and local interns and get extra
            hands on board to assist you in finding innovative solutions.
          </p>
        </div>
        <div v-if="Card === 'Matching'">
          <p class="text-center">
            Start a unique, personalised matching process that makes it easy to
            filter out profiles and view only those of international interns who
            possess your required skills. Reduce the time you spend scouring
            CVs. We advertise carefully, select and facilitate all Cape Town
            intern placements.
          </p>
        </div>
        <div v-if="Card === 'Assistance'">
          <h4 class="text-center text-white pb-5">
            What happens after you match with your intern?
          </h4>
          <p class="text-center">
            Your intern has 3 working days to sign the Internship Agreement upon
            a successful match. If they fail to do so, the position you
            advertised will automatically become available to other candidates
            again. After your intern signs the agreement, we assist them with
            their visa application and accommodation search, simplifying your
            side of proceedings. We also assist you with administration relevant
            to the internship.
          </p>
        </div>
        <div v-if="Card === 'Duration'">
          <p class="text-center">3-month placement</p>
          <p class="text-center">3-6-month placement</p>
          <p class="text-center">6 months plus placement</p>
        </div>
        <div v-if="Card === 'Free'">
          <p class="text-center">
            Because we believe start-ups and scale-ups need skilled talent with
            a global perspective the most, our services are purposefully budget
            friendly to assist Cape Town companies. Supporting small businesses
            and helping South African business infrastructure grow are both core
            to our mission. Tell us what you need and we’ll start the matching
            process.
          </p>
        </div>
        <div v-if="Card === 'Benefits'">
          <p class="text-center">Enjoy the benefits of short-term commitment</p>
          <p class="text-center">
            Find highly-qualified, pre-screened interns
          </p>
          <p class="text-center">
            Directly from top international business schools
          </p>
          <p class="text-center">
            International exposure and networking
          </p>
        </div>
      </div>
      <div class="text-center pb-5">
        <button
          type="button"
          class="btn btn-orange text-white hero-button"
          data-toggle="modal"
          data-target="#businessModal"
        >
          <h4 class="text-white dropshadow-text">Sign Up</h4>
        </button>
      </div>
    </section>

    <section class="bg-white">
      <div class="container">
        <div class="row">
          <div class="col-md-4 white-icons bg-gb sm-pt p-5 text-center">
            <svg class="icon-svg">
              <use xlink:href="../assets/screening.svg#Layer_1"></use>
            </svg>
            <h4 class="text-white">
              Highly qualified, pre-screened interns
            </h4>
            <p class="text-center text-white">
              Reduce your time spent on reading CVs and find an intern fast. We
              advertise, carefully select and facilitate all placements.
            </p>
          </div>
          <div class="col-md-4 white-icons bg-orange sm-pt p-5 text-center">
            <svg class="icon-svg">
              <use xlink:href="../assets/short-term.svg#Layer_1"></use>
            </svg>
            <h4 class="text-white">
              Short-term commitment
            </h4>
            <p class="text-center text-white">
              Interns generally stay for 3-6 months, giving you the opportunity
              to assign a full project.
            </p>
          </div>
          <div class="col-md-4 white-icons bg-gb sm-pt p-5 text-center">
            <svg class="icon-svg">
              <use xlink:href="../assets/global.svg#Layer_1"></use>
            </svg>
            <h4 class="text-white">
              International exposure
            </h4>
            <p class="text-center text-white">
              Interns bring their global skills and experience to your company
              which might be the opportunity for you to grow or explore new
              horizons.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="home-3 sm-pt sm-pb bg-white">
      <div class="container">
        <h2 class="sm-pb text-center">
          How it works
        </h2>
        <div class="grid-container1 text-center">
          <HowItWorksBusiness></HowItWorksBusiness>
        </div>
      </div>
    </section>

    <section class="container lg-pt pb-3">
      <h3 class="text-white text-center">
        SOME OF OUR MOST POPULAR APPLICATIONS
      </h3>
      <JobPositions></JobPositions>
    </section>
    <ModalBusiness></ModalBusiness>
  </main>
</template>

<script>
export default {
  data() {
    return {
      Card: "Qualified",
    };
  },
  metaInfo() {
    return {
      title: "Intern Cape Town - Find an Intern for Your Business",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "We assist in helping you find the best candidates to interview. You only deal with top candidates that meet your needs, reducing the time and expence required to fill the position. ",
        },
      ],
    };
  },
};
</script>
