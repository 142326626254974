<template>
  <div class="home">
    <section class="hero home-hero">
      <div class="container text-center text-md-left p-3">
        <div
          class="row d-flex justify-content-center justify-content-md-start w-100"
        >
          <h1 class="hero-header text-white  text-center text-md-left">
            <span class="font-weight-light text-capitalize"
              >Internship placements in Cape Town for</span
            >
            <br /><span class="font-weight-bolder text-capitalize"
              >Students and Graduates from around the world!</span
            >
          </h1>
          <button
            type="button"
            class="btn btn-yellow text-white hero-button"
            data-toggle="modal"
            data-target="#internModal"
          >
            <h4 class="text-white dropshadow-text">For Interns</h4>
          </button>
          <button
            type="button"
            class="btn btn-orange text-white hero-button"
            data-toggle="modal"
            data-target="#businessModal"
          >
            <h4 class="text-white dropshadow-text">For Business</h4>
          </button>
        </div>
      </div>
    </section>
    <section class="home-1 container sm-gt">
      <h2 class="text-center">The Internship Experience of your Dreams</h2>
      <p class="text-center">
        We provide international internship applicants with a wide variety of
        choice between challenging and rewarding roles. Cape Town businesses
        want interns who bring an international perspective as much as our
        interns want to experience working in Cape Town. Once you have connected
        with our network of diverse start-ups and small businesses in Cape Town,
        we can assist you in procuring a visa.
      </p>
    </section>
    <section class="home-2 sm-gt">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-6 w-100">
            <div class="row home-2-bgi">
              <div class="col-md-4"></div>
              <div class="col-md-8 col-12 xl-gt p-5">
                <div class="text-md-right">
                  <h3 class="text-white pb-4">Get The Internship You Desire</h3>
                  <ul class="list-unstyled text-white">
                    <li class="text-md-right">Personalised Matching Process</li>
                    <li class="text-md-right">Apply To Multiple Internships</li>
                    <li class="text-md-right">Pay Only When You Match</li>
                    <li class="text-md-right">Assistance</li>
                    <li class="text-md-right">
                      Contribution Towards Your Stay
                    </li>
                  </ul>
                  <button
                    type="button"
                    class="btn btn-yellow text-white hero-button"
                    data-toggle="modal"
                    data-target="#internModal"
                  >
                    <h4 class="text-white dropshadow-text">Apply Now</h4>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="row home-2-bgb">
              <div class="col-md-8 xl-gt p-5">
                <div>
                  <h3 class="text-white pb-4">We Find The Intern You Need</h3>
                  <ul class="list-unstyled text-white">
                    <li class="text-start">
                      Highly-Qualified, Pre-Screened Interns
                    </li>
                    <li>Top International and Local Applicants</li>
                    <li>Short-Term Commitment</li>
                    <li>Budget Friendly</li>
                    <li>International Exposure</li>
                  </ul>
                  <button
                    type="button"
                    class="btn btn-orange text-white hero-button"
                    data-toggle="modal"
                    data-target="#businessModal"
                  >
                    <h4 class="text-white dropshadow-text">Sign Up</h4>
                  </button>
                </div>
              </div>
              <div class="col-md-4"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="home-3 sm-gt">
      <div class="container">
        <h2 class="sm-pb text-center">
          How it works
        </h2>
        <div class="grid-container1 text-center">
          <HowItWorksIntern></HowItWorksIntern>
          <HowItWorksBusiness></HowItWorksBusiness>
        </div>
      </div>
    </section>
    <section class="home-4 categories sm-pt sm-gt sm-pb">
      <div class="container  ">
        <h2 class="text-center text-white sm-pb">Popular Categories</h2>
        <div class="grid-container2">
          <div class="card">
            <router-link to="/business-engineer">
              <img
                src="../assets/card-image2.jpg"
                class="card-img-top"
                alt=""
              />
              <div class="card-body">
                <h4 class="text-center">Business Engineer</h4>
                <!-- <p class="p-3">
                  A business engineer is usually someone with a background in
                  architecture, engineering and/or other related fields. Their
                  prior focus is developing and maintaining relationships with
                  clients.
                </p> -->
              </div>
            </router-link>
          </div>

          <div class="card">
            <router-link to="/finance-assistant">
              <img
                src="../assets/card-image3.jpg"
                class="card-img-top"
                alt=""
              />
              <div class="card-body">
                <h4 class="text-center">Finance Assistant</h4>
                <!-- <p class="p-3">
                  A finance assistant is someone assisting the finance director
                  in managing, processing and troubleshooting various accounts
                  and transactions as well as performing routine calculations to
                  produce analyses.
                </p> -->
              </div>
            </router-link>
          </div>

          <div class="card">
            <router-link to="/project-manager">
              <img
                src="../assets/card-image4.jpg"
                class="card-img-top"
                alt=""
              />
              <div class="card-body">
                <h4 class="text-center">Project Manager</h4>
                <!-- <p class="p-3">
                  Project management responsibilities include delivering every
                  project on time within budget and scope. While a project
                  manager should have a background in business skills and
                  management, they should also know budgeting and analy
                </p> -->
              </div>
            </router-link>
          </div>

          <div class="card">
            <router-link to="/graphic-designer">
              <img
                src="../assets/card-image1.jpg"
                class="card-img-top"
                alt=""
              />
              <div class="card-body">
                <h4 class="text-center">Graphic Designer</h4>
                <!-- <p class="p-3">
                  Graphic designers create visual concepts, by hand or using
                  computer software, to communicate ideas that inspire or
                  captivate consumers. A graphic designer develops the overall
                  layout and production design for advertisements and corporate
                  reports.
                </p> -->
              </div>
            </router-link>
          </div>

          <div class="card">
            <router-link to="/web-developer">
              <img
                src="../assets/card-image5.jpg"
                class="card-img-top"
                alt=""
              />
              <div class="card-body">
                <h4 class="text-center">Web Developer</h4>
                <!-- <p class="p-3">
                  A web developer is responsible for designing, coding and
                  modifying websites, from layout to function. Also, they need
                  to follow client’s specifications. Therefore, they strive to
                  create visually appealing sites that feature user-friendly
                  design and clear navigation.
                </p> -->
              </div>
            </router-link>
          </div>

          <div class="card">
            <router-link to="/social-media-engagement-manager">
              <img
                src="../assets/social-media-marketing.webp"
                class="card-img-top"
                alt=""
              />
              <div class="card-body">
                <h4 class="text-center">Social Media Manager</h4>
                <!-- <p class="p-3">
                  The Communications Manager is responsible for managing all
                  internal and external communications for the company ensuring
                  its messages are consistent throughout various social media
                  (Facebook, Instagram, Twitter…) and public relation platforms.
                </p> -->
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <ModalIntern></ModalIntern>
    <ModalBusiness></ModalBusiness>
    <ModalFAQ></ModalFAQ>
  </div>
</template>

<script>
export default {
  name: "Home",
  metaInfo() {
    return {
      title:
        "Intern Cape Town - Internship placements in Cape Town for Students and Graduates from around the world!",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "We provide international and local internship applicants with a wide variety of choice between challenging and rewarding roles across Cape Town.",
        },
      ],
    };
  },
};
</script>
