<template>
  <div class="homepage">
    <HomePage></HomePage>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
