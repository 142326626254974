<template
  ><div>
    <footer class="bg-gb" id="footer">
      <div class="container sm-pt sm-pb">
        <div class="grid-container3">
          <div class="row">
            <div class=" col-xl-7 text-uppercase">
              <h3 class="display-4 my-5 text-capitalize text-white">
                Recent Information
              </h3>
              <ul class="text-black list-unstyled">
                <li>
                  <router-link class="text-dg" to="/beforect"
                    ><i class="fas fa-file-alt"></i> Before Coming To Cape
                    Town</router-link
                  >
                </li>
                <li>
                  <router-link class="text-dg" to="/internship-process"
                    ><i class="fas fa-file-alt"></i> The Internship
                    Process</router-link
                  >
                </li>
                <li>
                  <router-link class="text-dg" to="/lifestyle"
                    ><i class="fas fa-file-alt"></i> Cape Town
                    Lifestyle</router-link
                  >
                </li>
                <li>
                  <router-link class="text-dg" to="/sixmonths"
                    ><i class="fas fa-file-alt"></i> Six Months In Cape
                    Town</router-link
                  >
                </li>
                <li>
                  <router-link class="text-dg" to="/success-story"
                    ><i class="fas fa-file-alt"></i> Success Story</router-link
                  >
                </li>
                <li>
                  <router-link class="text-dg" to="/intern-profile"
                    ><i class="fas fa-file-alt"></i> Intern Profile</router-link
                  >
                </li>
                <li>
                  <router-link class="text-dg" to="/right-intern"
                    ><i class="fas fa-file-alt"></i> The Right
                    Intern</router-link
                  >
                </li>
              </ul>
            </div>
            <div class=" col-xl-5 text-uppercase">
              <h3 class="display-4  my-5 text-capitalize text-white">
                Site Links
              </h3>
              <ul class="text-black  list-unstyled">
                <li>
                  <router-link class="text-dg" to="/"
                    ><i class="fas fa-sort-down  fa-rotate-270"> </i>
                    Home</router-link
                  >
                </li>
                <li>
                  <router-link class="text-dg" to="/intern">
                    <i class="fas fa-sort-down  fa-rotate-270"> </i> For
                    Interns</router-link
                  >
                </li>
                <li>
                  <router-link class="text-dg" to="/business">
                    <i class="fas fa-sort-down  fa-rotate-270"> </i> For
                    Businesses</router-link
                  >
                </li>
                <li>
                  <router-link class="text-dg" to="/articles"
                    ><i class="fas fa-sort-down  fa-rotate-270"> </i> PROGRAM
                    INFORMATION</router-link
                  >
                </li>
              </ul>
            </div>
          </div>

          <div>
            <h3 class="display-4 my-5 text-capitalize text-white">
              We would love to hear from you
            </h3>
            <div>
              <ul
                class="list-unstyled mb-0 d-flex justify-content-between tex-black contact-grid"
              >
                <li class="">
                  <a
                    href="tel:+27765753518"
                    class="footer-contact text-dg text-decoration-none"
                    ><span><i class="fas fa-mobile-alt mt-4"></i></span> +27 76
                    575 3518</a
                  >
                </li>
                <li class="pb-5">
                  <a
                    href="mailto:info@interncapetown.com"
                    class="footer-contact text-dg text-decoration-none"
                    ><span><i class="fas fa-envelope mt-4"></i></span>
                    info@interncapetown.com</a
                  >
                </li>
              </ul>
            </div>
            <form>
              <!--Grid row-->
              <div class="row">
                <!--Grid column-->
                <div class="col-md-6">
                  <div class="form-floating">
                    <input
                      v-model="name"
                      type="text"
                      id="name"
                      name="name"
                      class="form-control"
                      placeholder="Name"
                    /><label for="name" class="">Name</label>
                  </div>
                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-md-6">
                  <div class="form-floating mb-0">
                    <input
                      v-model="number"
                      type="text"
                      id="email"
                      name="email"
                      class="form-control"
                      placeholder="Phone"
                    /><label for="email" class="">Phone</label>
                  </div>
                </div>
                <!--Grid column-->
              </div>
              <!--Grid row-->

              <!--Grid row-->
              <div class="row">
                <div class="col-md-12">
                  <div class="form-floating mb-0">
                    <input
                      v-model="email"
                      type="text"
                      id="subject"
                      name="subject"
                      class="form-control"
                      placeholder="email"
                    /><label for="subject" class="">Email</label>
                  </div>
                </div>
              </div>
              <!--Grid row-->

              <!--Grid row-->
              <div class="row">
                <!--Grid column-->
                <div class="col-md-12">
                  <div class="form-floating">
                    <textarea
                      v-model="message"
                      type="text"
                      id="message"
                      name="message"
                      rows="4"
                      class="form-control md-textarea"
                      placeholder="leave your message here"
                    ></textarea
                    ><label for="message">Your message</label>
                  </div>
                </div>
              </div>
              <div class="text-center text-md-left footer-button">
                <button
                  type="button"
                  class="btn-block btn-dg text-white btn"
                  @click="getInContact()"
                >
                  <div
                    v-if="loading"
                    class="spinner-border spinner-border-md"
                  ></div>
                  <p class="text-white">
                    <span v-if="loading"> Sending</span>
                    <span v-else>{{ title }}</span>
                  </p>

                  <!-- <h5 className="pt-3 text-uppercase">{{ title }}</h5> -->
                </button>
              </div>
              <!--Grid row-->
            </form>

            <div class="status"></div>
          </div>
        </div>
      </div>
    </footer>
    <div
      class="bg-dg text-center text-uppercase p-3  text-white d-flex justify-content-center"
    >
      <div class="bottom-footer ">
        All rights reserved Intern Cape Town |
        <router-link to="/terms-of-service" class="bf-text"
          >Terms of service</router-link
        >
        |
        <router-link to="/privacy-and-cookies-policy" class="bf-text"
          >Privacy and Cookies Policy</router-link
        >
        | Designed by
        <a href="https:dragonwebdesign.co.za" class="bf-text"
          >Dragon Web Design</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
export default {
  name: "Footer",
  data() {
    return {
      loading: false,
      title: "Send",
      error: "",
      name: "",
      number: "",
      email: "",
      message: "",
    };
  },
  methods: {
    getInContact() {
      if (this.name === "" || this.email === "") {
        this.error = "Please fill out all fields!";
        setTimeout(() => (this.error = ""), 5000);
      } else {
        axios
          .post(
            "/phpmailer/src/getInContactICT.php",

            qs.stringify({
              name: this.name,
              email: this.email,
              number: this.number,
              message: this.message,
            })
          )
          .then((response) => {
            this.loading = !false;
            this.title = "Sent :)";
            setTimeout(() => (this.title = "Send"), 5000);
            setTimeout(() => {
              this.loading = !true;
            }, 2000);
            this.name = "";
            this.email = "";
            this.number = "";
            this.message = "";
            console.log(response);
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },
  },
};
</script>
