<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="businessModal"
      tabindex="-1"
      aria-labelledby="businessModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-orange intern p-4">
          <div class="modal-header">
            <h3 class="modal-title text-white pt-5" id="businessModalLabel">
              Business Signup
            </h3>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times "></i>
            </button>
          </div>
          <div class="modal-body pt-5">
            <form class=" pb-5">
              <!--Grid row-->
              <div class="row">
                <!--Grid column-->
                <div class="col-md-12">
                  <div class="form-floating">
                    <input
                      v-model="name"
                      type="text"
                      id="name"
                      name="name"
                      class="form-control"
                      placeholder="Name"
                    /><label for="name" class="">Name</label>
                  </div>
                </div>
                <!--Grid column-->
              </div>
              <!--Grid row-->
              <div class="row">
                <!--Grid column-->
                <div class="col-md-12">
                  <div class="form-floating">
                    <select
                      v-model="code"
                      type="text"
                      id="code"
                      name="code"
                      class="form-control"
                    >
                      <option
                        v-for="(countryCode, index) in country"
                        :key="index"
                        v-bind:value="countryCode.callingCodes"
                        >{{ countryCode.name }}</option
                      >
                    </select>
                    <label for="code" class="country-code">Country</label>
                  </div>
                </div>
                <!--Grid column-->
                <!--Grid column-->
                <div class="col-4">
                  <p class="text-underlined label-style">
                    Calling Code<br /><span
                      class="text-black font-weight-bolder"
                      >(+{{ code[0] }})</span
                    >
                  </p>
                </div>
                <div class="col-md-8">
                  <div class="form-floating mb-0">
                    <input
                      v-model="number"
                      type="text"
                      id="phone"
                      name="phone"
                      class="form-control"
                      placeholder="Phone"
                    /><label for="phone" class="">Phone</label>
                  </div>
                </div>
                <!--Grid column-->
              </div>

              <!--Grid row-->
              <div class="row">
                <div class="col-md-12">
                  <div class="form-floating mb-0">
                    <input
                      v-model="email"
                      type="text"
                      id="email"
                      name="email"
                      class="form-control"
                      placeholder="email"
                    /><label for="email" class="">Email</label>
                  </div>
                </div>
              </div>
              <!--Grid row-->

              <!--Grid row-->
              <div class="row">
                <div class="col-md-12">
                  <div class="form-floating mb-0">
                    <input
                      v-model="type"
                      type="text"
                      id="type"
                      name="type"
                      class="form-control"
                      placeholder="type"
                    /><label for="type" class=""
                      >What is the position available for the internship?</label
                    >
                  </div>
                </div>
              </div>
              <!--Grid row-->

              <!--Grid row-->
              <div class="row">
                <div class="col-md-12">
                  <div class="form-floating mb-0">
                    <input
                      v-model="time"
                      type="text"
                      id="time"
                      name="time"
                      class="form-control"
                      placeholder="time"
                    /><label for="time" class=""
                      >What is the length of the internship?</label
                    >
                  </div>
                </div>
              </div>
              <!--Grid row-->

              <!--Grid row-->
              <div class="row">
                <div class="col-md-12">
                  <div class="form-floating mb-0">
                    <input
                      v-model="start"
                      type="text"
                      id="start"
                      name="start"
                      class="form-control"
                      placeholder="start"
                    /><label for="start" class=""
                      >When would you like the intern to start?</label
                    >
                  </div>
                </div>
              </div>
              <!--Grid row-->

              <!--Grid row-->
              <div class="row">
                <div class="col-md-12">
                  <div class="form-floating-textarea mb-0">
                    <label for="brief" class="">Job Specifications:</label>
                    <textarea
                      class="form-control"
                      id="brief"
                      name="brief"
                      rows="5"
                      v-model="brief"
                    ></textarea>
                  </div>
                </div>
              </div>
              <!--Grid row-->

              <div class="text-center text-md-left submit-button">
                <button
                  type="button"
                  class="btn-gb text-white estimate-submit btn btn-block"
                  @click="getInContact()"
                >
                  <div
                    v-if="loading"
                    class="spinner-border spinner-border-md"
                  ></div>
                  <p class="text-white pt-3">
                    <span v-if="loading"> Sending</span>
                    <span v-else>{{ title }}</span>
                  </p>

                  <!-- <h5 className="pt-3 text-uppercase">{{ title }}</h5> -->
                </button>
              </div>
              <!--Grid row-->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import qs from "qs";
import Country from "../../public/Country.json";
export default {
  data() {
    return {
      loading: false,
      title: "Signup",
      error: "",
      name: "",
      number: "",
      email: "",
      type: "",
      time: "",
      start: "",
      brief: "",
      country: Country,
      code: ["27"],
      sendError: "",
    };
  },
  methods: {
    getInContact() {
      if (this.name === "" || this.email === "") {
        this.error = "Please fill out all fields!";
        setTimeout(() => (this.error = ""), 5000);
      } else {
        axios
          .post(
            "/phpmailer/src/business.php",

            qs.stringify({
              name: this.name,
              email: this.email,
              number: "(+" + this.code[0] + ") " + this.number,
              type: this.type,
              time: this.time,
              start: this.start,
              brief: this.brief,
            })
          )
          .then((response) => {
            this.loading = !false;
            this.title = "Sent :)";
            setTimeout(() => (this.title = "Send"), 5000);
            setTimeout(() => {
              this.loading = !true;
            }, 2000);
            this.name = "";
            this.email = "";
            this.number = "";
            this.code = ["27"];
            this.type = "";
            this.time = "";
            this.start = "";
            this.brief = "";
            console.log(response);
          })
          .catch(function(error) {
            console.log(error);
            this.loading = !false;
            this.title = "Something Went Wrong :(";
          });
      }
    },
  },
};
</script>
