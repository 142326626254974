<template>
  <div class="">
    <div id="home-5" class="">
      <div class="job-positions w-100">
        <div class="position-grid positions sm-pt">
          <div class="form-check">
            <input
              v-model="Card"
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="all"
              value="All"
            />
            <label class="form-check-label" for="all">
              All
            </label>
          </div>
          <div class="form-check">
            <input
              v-model="Card"
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="admin"
              value="Admin"
            />
            <label class="form-check-label" for="admin">
              Admin
            </label>
          </div>
          <div class="form-check">
            <input
              v-model="Card"
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="digital"
              value="Digital"
            />
            <label class="form-check-label" for="digital">
              Digital
            </label>
          </div>
          <div class="form-check">
            <input
              v-model="Card"
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="it"
              value="IT"
            />
            <label class="form-check-label" for="it">
              IT
            </label>
          </div>
          <div class="form-check">
            <input
              v-model="Card"
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="marketing"
              value="Marketing"
            />
            <label class="form-check-label" for="marketing">
              Marketing
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="contact-grid">
      <div v-if="Card === 'All' || Card === 'Admin' || Card === 'IT'">
        <BACard></BACard>
      </div>
      <div v-if="Card === 'All' || Card === 'Digital' || Card === 'IT'">
        <BECard></BECard>
      </div>
      <div v-if="Card === 'All' || Card === 'Digital' || Card === 'Marketing'">
        <ComCard></ComCard>
      </div>
      <div v-if="Card === 'All' || Card === 'Digital' || Card === 'Marketing'">
        <DMCard></DMCard>
      </div>
      <div v-if="Card === 'All' || Card === 'Admin'">
        <FACard></FACard>
      </div>
      <div v-if="Card === 'All' || Card === 'Digital' || Card === 'IT'">
        <GDCard></GDCard>
      </div>
      <div v-if="Card === 'All' || Card === 'Admin' || Card === 'Marketing'">
        <PMCard></PMCard>
      </div>
      <div v-if="Card === 'All' || Card === 'Admin' || Card === 'Marketing'">
        <SMSCard></SMSCard>
      </div>
      <div v-if="Card === 'All' || Card === 'Digital' || Card === 'IT'">
        <WebCard></WebCard>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Card: "All",
    };
  },
};
</script>
